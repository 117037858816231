import {MediaImage} from "../media/media--image";
import {HERO_IMAGE_STYLE} from "../../../types/image-styles";
import classNames from "classnames";
import {CSSProperties} from "react";
import dynamic from "next/dynamic";
const FormattedText = dynamic(() => import("../../formatted-text").then((mod) => mod.FormattedText));

export function ParagraphImageBlock({paragraph, ...props}) {
  const style: CSSProperties = {};
  const rounded = !paragraph?.field_image_block_square;
  const size = paragraph?.field_image_block_size ?? "";
  const maxHeight = paragraph?.field_image_block_max_height ?? "";

  if (maxHeight) {
    style.maxHeight = `${maxHeight}px`;
    style.width = "auto";
  }

  return (
      <div className="relative">
        <div className={classNames(
          "relative mx-auto flex md:flex-col h-full items-end bg-transparent overflow-hidden image-block-container",
          {"w-full": !size?.length},
          {
            "max-w-4xl": !paragraph?.field_image_block_full,
            "drop-shadow-sm": paragraph?.field_image_block_drop_shadow,
          },
          {
            // Fixes container sizes.
            "w-1/2": size === "1/2",
            "w-2/2": size === "2/2",
            "w-1/3": size === "1/3",
            "w-2/3": size === "2/3",
            "w-3/3": size === "3/3",
            "w-1/4": size === "1/4",
            "w-2/4": size === "2/4",
            "w-3/4": size === "3/4",
            "w-4/4": size === "4/4",
            "w-1/5": size === "1/5",
            "w-2/5": size === "2/5",
            "w-3/5": size === "3/5",
            "w-4/5": size === "4/5",
            "w-5/5": size === "5/5",
          }
        )}>
          <MediaImage
            loading="lazy"
            media={paragraph.field_image_block_image}
            key={`hero-image--${paragraph.field_image_block_image.id}`}
            sizes={`(min-width: 1024px) 1200px, 600px`}
            style={style}
            className={classNames(
            "inset-0 transition-all overflow-hidden opacity-1 duration-1000 mx-auto max-w-full",
              {"rounded-md": rounded}
            )}/>
        </div>
        {paragraph?.field_image_block_caption?.processed && (
          <div className={classNames(
            "relative w-full mx-auto flex md:flex-col h-full pt-4",
            {"max-w-4xl": !paragraph?.field_image_block_full}
          )}>
            <FormattedText processed={paragraph.field_image_block_caption?.processed}/>
          </div>
        )}
      </div>
  )
}
